import { createTheme, Theme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { accentBlue, crassoneRed } from 'color-palette';

// Create a theme instance.
export const companyTheme = createTheme({
  palette: {
    primary: {
      light: crassoneRed.light,
      main: crassoneRed.main,
      dark: crassoneRed.dark,
    },
    secondary: {
      light: accentBlue.light,
      main: accentBlue.main,
      dark: accentBlue.dark,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});

export const insuranceAgencyTheme = createTheme({
  palette: {
    primary: {
      main: '#C23D36',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});

export const crassoneTheme = createTheme({
  palette: {
    primary: {
      main: '#C23D36',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});

export const intendedTheme = (pathname: string): Theme => {
  if (pathname.startsWith('/crassone')) {
    return crassoneTheme;
  }
  if (pathname.startsWith('/insurance-agency')) {
    return insuranceAgencyTheme;
  }
  return companyTheme;
};
