import { createContext } from 'react';
import { Severity } from 'providers/SnackbarProvider/Severity';

export const SnackbarDisplayContext = createContext<{
  show: (message: string, severity: Severity) => void;
  hide: () => void;
}>({
  show: () => {
    return;
  },
  hide: () => {
    return;
  },
});
