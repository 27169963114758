/* eslint-disable */
import * as Sentry from '@sentry/nextjs';
import { CaptureConsole } from '@sentry/integrations';

const isPrd = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'production';
const sentryDsn = isPrd
  ? process.env.NEXT_PUBLIC_PRD_SENTRY_DSN
  : process.env.NEXT_PUBLIC_SENTRY_DSN;

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (
        error instanceof Error &&
        error.message.match(/Abort route change\. Please ignore this error\./i)
      ) {
        return null;
      }

      return event;
    },
  });
}
