import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { formatDate, FormatDateOption } from 'utils/date';

export class LocalizedDateFnsUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: Date): string {
    return formatDate(date, FormatDateOption.noYear);
  }
  getCalendarHeaderText(date: Date): string {
    return format(date, 'yyyy年 M月');
  }
}
