import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider as AuthProvider } from 'next-auth/client';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import jaLocale from 'date-fns/locale/ja';
import { intendedTheme } from 'theme';
import { LocalizedDateFnsUtils } from 'LocalizedDateFnsUtils';
import { apolloClient } from 'apolloClient';
import { useRouter } from 'next/router';
import { gtagPageView } from 'gtagPageView';
import { SnackbarProvider } from 'providers/SnackbarProvider';

const MyApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  const router = useRouter();
  const theme = intendedTheme(router.pathname);

  useEffect(() => {
    const handleRouteChange = () => gtagPageView(router.asPath);
    router.events.on('routeChangeComplete', handleRouteChange);
    // eslint-disable-next-line
  }, [router.events]);

  React.useEffect(() => {
    // Material UI
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Head>
        <title key="pageTitle">クラッソーネビズ</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <AuthProvider
        session={pageProps.session}
        options={{ clientMaxAge: 5 * 60 }}
      >
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider
              utils={LocalizedDateFnsUtils}
              locale={jaLocale}
            >
              <SnackbarProvider>
                <CssBaseline />
                <Component {...pageProps} />
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </ApolloProvider>
      </AuthProvider>
    </React.Fragment>
  );
};

export default MyApp;
