export const getUuids = (): [string | null, string | null] => {
  if (typeof window === 'undefined') {
    return [null, null];
  }

  return [
    localStorage.getItem('user-uuid'),
    localStorage.getItem('company-uuid'),
  ];
};

export const setUuids = (
  userUuid: string,
  companyUuid: string | null,
): void => {
  if (typeof window === 'undefined') {
    return;
  }

  localStorage.setItem('user-uuid', userUuid);
  if (companyUuid) {
    localStorage.setItem('company-uuid', companyUuid);
  } else {
    localStorage.removeItem('company-uuid');
  }
  return;
};

export const clearUuids = (): void => {
  if (typeof window === 'undefined') {
    return;
  }

  localStorage.removeItem('company-uuid');
  localStorage.removeItem('user-uuid');
  return;
};
