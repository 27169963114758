import { Snackbar as MuiSnackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbarState } from './useSnackbarState';

export const Snackbar = (): JSX.Element => {
  const { open, message, severity, hide } = useSnackbarState();

  return (
    <>
      <MuiSnackbar
        key={severity}
        open={open}
        autoHideDuration={3000}
        onClose={() => hide()}
      >
        <Alert severity={severity} elevation={6} variant="filled">
          {message}
        </Alert>
      </MuiSnackbar>
    </>
  );
};
