import { grey } from '@material-ui/core/colors';

//Navy
export const crassoneNavy = {
  main: '#1E2D3C',
  light: '#475666',
  dark: '#000216',
};

//Red
export const crassoneRed = {
  main: '#F01E28',
  light: '#FF6052',
  dark: '#B50000',
};

//Blue
export const accentBlue = {
  main: '#009AFA',
  light: '#68CAFF',
  dark: '#006CC6',
};

//Black and White
export const black = '#000000';
export const white = '#FFFFFF';

//Navy Grey
export const navyGrey = {
  900: '#121D25',
  800: '#1E2D3C', //navyGrey[800]はcrassoneNavy.mainと同色
  700: '#495862',
  600: '#758088',
  500: '#A1A9AE',
  400: '#B7BDC1',
  300: '#DEE0E2',
  200: '#EDEEEF',
  100: '#F4F5F6',
  50: '#FAFAFB',
};

//Text Color
//Text on light backgrounds:黒文字（背景色がライトな時に使用する)
export const textOnLightBackground = {
  high: 'rgba(30, 45, 60, 1)', //crassoneNavy.mainと同色
  medium: 'rgba(30, 45, 60, 0.7)', //highの70％
  disabled: 'rgba(30, 45, 60, 0.4)', //highの40％
};
//Text on dark backgrounds:白文字(背景色がダークな時に使用する)
export const textOnDarkBackground = {
  high: 'rgba(255, 255, 255, 1)', //crassoneNavy.mainと同色
  medium: 'rgba(255, 255, 255, 0.7)', //highの70％
  disabled: 'rgba(255, 255, 255, 0.5)', //highの40％
};

//Icon Color
//Icons on light backgrounds:黒色(背景色がライトな時に使用する)
export const iconOnLightBackground = {
  active: 'rgba(30, 45, 60, 1)', //crassoneNavy.mainと同色
  inactive: 'rgba(30, 45, 60, 0.6)', //activeの70％
  disabled: 'rgba(30, 45, 60, 0.4)', //highの40％
};
//Icons  on dark backgrounds:白色(背景色がダークな時に使用する)
export const iconOnDarkBackground = {
  active: 'rgba(255, 255, 255, 1)', //crassoneNavy.mainと同色
  inactive: 'rgba(255, 255, 255, 0.7)', //activeの70％
  disabled: 'rgba(255, 255, 255, 0.4)', //highの40％
};

//ボーダー色
export const borderGrey = navyGrey[300];

//共通の背景色
export const baseBackgroundGrey = navyGrey[100];

//InputのBG
export const inputBackgroundGray = grey[50];

//Tag
export const tagColors = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#CDDC39',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#9E9E9E',
];

// FIXME: これは暫定
export const readOnlyTextColor = '#999';
export const readOnlyBackgroundColor = '#eee';
