import { SnackbarDisplayContext } from 'contexts/SnackbarDisplayContext';
import { SnackbarStateContext } from 'contexts/SnackbarStateContext';
import { ReactNode } from 'react';
import { Snackbar } from './Snackbar';
import { useSnackbarProvider } from './useSnackbarProvider';

type Props = {
  children: ReactNode;
};

export const SnackbarProvider = ({ children }: Props) => {
  const { open, message, severity, show, hide } = useSnackbarProvider();

  return (
    <SnackbarStateContext.Provider value={{ open, message, severity, hide }}>
      <SnackbarDisplayContext.Provider value={{ show, hide }}>
        {children}
      </SnackbarDisplayContext.Provider>
      <Snackbar />
    </SnackbarStateContext.Provider>
  );
};
