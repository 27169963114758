import config from 'config';

export const GA_ID = config.gaTrackingId || '';

export type CustomerUuids = {
  userUuid: string | null;
  companyUuid: string | null;
};

// PVを測定する
export const pageview = (url: string, uuids: CustomerUuids): void => {
  window.gtag('config', GA_ID || '', {
    page_path: url,
    custom_map: {
      dimension1: 'userUuid',
      dimension2: 'companyUuid',
    },
    ...uuids,
  });
};

type GaEventProps = {
  action: string;
  category: string;
  label: string;
  value?: string;
};

// GAイベントを発火させる
export const event = ({
  action,
  category,
  label,
  value = '',
}: GaEventProps): void => {
  if (!GA_ID) {
    return;
  }

  window.gtag('event', action, {
    event_category: category,
    event_label: JSON.stringify(label),
    value,
  });
};
