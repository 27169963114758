import { useCallback, useRef, useState } from 'react';
import { Severity } from './Severity';

type HookReturnType = {
  open: boolean;
  message: string;
  severity: Severity;
  show: (message: string, severity: Severity) => void;
  hide: () => void;
};

export const useSnackbarProvider = (): HookReturnType => {
  const [open, setOpen] = useState(false);
  const messageRef = useRef('');
  const severityRef = useRef<Severity>(Severity.Info);

  const show = useCallback(
    (message = '', severity: Severity = Severity.Info) => {
      messageRef.current = message;
      severityRef.current = severity;
      setOpen(true);
    },
    [setOpen],
  );

  const hide = useCallback(() => {
    setOpen(false);
    messageRef.current = '';
    severityRef.current = Severity.Info;
  }, [setOpen]);

  return {
    open,
    message: messageRef.current,
    severity: severityRef.current,
    show,
    hide,
  };
};
