import * as gtag from './lib/gtag';
import { getUuids } from './lib/uuid';

export const gtagPageView = (url: string): void => {
  if (!gtag.GA_ID) {
    return;
  }

  const [userUuid, companyUuid] = getUuids();

  gtag.pageview(url, {
    userUuid: userUuid,
    companyUuid: companyUuid,
  });
  return;
};
