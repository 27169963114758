import { createContext } from 'react';
import { Severity } from 'providers/SnackbarProvider/Severity';

export const SnackbarStateContext = createContext<{
  open: boolean;
  message: string;
  severity: Severity;
  hide: () => void;
}>({
  open: false,
  message: '',
  severity: Severity.Info,
  hide: () => {
    return;
  },
});
