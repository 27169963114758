type AppConfig = {
  apiBaseUrl: string | undefined;
  gaTrackingId: string | undefined;
  insuranceAgencyTel: string;
  sentryDsn: string | undefined;
};

const config: AppConfig = {
  apiBaseUrl:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'production'
      ? process.env.NEXT_PUBLIC_PRD_API_BASE_URL
      : process.env.NEXT_PUBLIC_API_BASE_URL,
  gaTrackingId:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'production'
      ? process.env.NEXT_PUBLIC_PRD_GOOGLE_ANALYTICS_ID
      : process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
  sentryDsn:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'production'
      ? process.env.NEXT_PUBLIC_PRD_SENTRY_DSN
      : process.env.NEXT_PUBLIC_SENTRY_DSN,
  insuranceAgencyTel:
    process.env.NODE_ENV === 'production' ? '052-581-0120' : '03-XXXX-XXXX',
};
export default config;
